/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        //ADD ID TO HEADING
        $("h2").each(function() {
            var hyphenated = $(this).text().replace(/\s/g,'-');
            $(this).attr('id',hyphenated);
        }); 

        //SMOOTH SCROLL TO ANCHORLINKS
        setTimeout(function() {
            if (location.hash) {
              window.scrollTo(0, 0);
              target = location.hash.split('#');
              if(target[1].indexOf('!/') > -1){
                smoothScrollTo($('#'+'view'));
              } else {
                smoothScrollTo($('#'+target[1]));
              }
            }
          }, 1);
          
          $('a[href^="#"]').click(function() {
            if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
              smoothScrollTo($(this.hash));
              return false;
            }
          });
          
          function smoothScrollTo(target) {
            target = target.length ? target : $('[id=' + this.hash.slice(1) +']');
            if (target.length) {
              $('html,body').animate({
                scrollTop: target.offset().top - 100
              }, 1000);
            }
          }

        $('.tabBar .container li').click(function(e) {
          e.preventDefault();
          $(this).addClass('active').siblings('.active').removeClass('active');
          var currentTab = $(this).attr('data-option');
          $('#' + currentTab).addClass('show').siblings('.show').removeClass('show');
        });

        $('.guide__content .container .link').click(function(e) {
          e.preventDefault();
          var currentTab = $(this).attr('data-option');
          $('.tabBar .container li').filter("[data-option='" + currentTab + "']").addClass('active').siblings('.active').removeClass('active');
          $('#' + currentTab).addClass('show').siblings('.show').removeClass('show');
          $('html, body').animate({
              scrollTop: $('#' + currentTab).offset().top - 200
          }, 400);
        });

        $('.guide__content .group-container li a').click(function(e) {
          e.preventDefault();
          $(this).parent().toggleClass('active').siblings('.active').removeClass('active');
          $('html, body').animate({
              scrollTop: $(this).offset().top - 200
          }, 400);
        });

        // Footer
        $('#menu-footer-menu li.menu-item-has-children').addClass('groupToggle');
        $('.groupToggle').prepend('<img class="plus" src="/app/themes/registerforskning/assets/images/Plus-red.svg" />');
        $('.groupToggle').prepend('<img class="minus" src="/app/themes/registerforskning/assets/images/Minus.svg" />');
        
        $('.groupToggle a').click(function(e) {
          e.preventDefault();
          $(this).parent().toggleClass('active').siblings('.active').removeClass('active').find('.minus, .plus').toggle();
          $(this).parent().find('.plus, .minus').toggle();
        });

        $('.groupToggle .sub-menu a').click(function() {
            var url = $(this).attr('href');
            window.open(url);
        });

        $('.backToTop a').click(function(e) {
          $("html, body").animate({ scrollTop: 0 }, "fast");
        });

        //MENU

        $('.current_page_parent').parent().parent().addClass('current-menu-ancestor');

        //STICKY MENU


        var stick = $(".banner");
        var pos = stick.position();         

        $(window).scroll(function() {
          var windowpos = $(window).scrollTop();
          if (windowpos != pos.top & windowpos >= 200) {
            stick.addClass("stick");
          } else {
            stick.removeClass("stick"); 
          }
        });

        $('.nav-primary li.menu-item-has-children').click(function(e) {
          $(this).toggleClass('active').siblings('.active').removeClass('active');
        });

        $('main, footer, .nav-search').click(function(e) {
            $('.nav-primary li.menu-item-has-children').toggleClass('active').siblings('.active').removeClass('active');
        });

        $(window).on("load",function(){
          if($(window).width() < 1160) {
              $(".nav-primary, .nav-secondary, .brand, .nav-top").addClass("mobile");
              setTimeout(function() {
                $(".nav-primary, .nav-secondary, .brand, .nav-top .inner").fadeIn();
                $(".menu-btn").fadeIn();
              }, 100);
          } else {
              setTimeout(function() {
                $(".nav-primary, .nav-secondary, .brand, .nav-top .inner").fadeIn();
                $(".menu-btn").hide();
              }, 100);
          }
        });

        $(window).resize(function() {
          if($(window).width() > 1160) {
            $(".nav-primary, .nav-secondary, .brand, .nav-top").removeClass("mobile"); 
            $(".menu-btn").hide();
          } else {
            $(".nav-primary, .nav-secondary, .brand, .nav-top").addClass("mobile");
            $(".menu-btn").show();
          }
        });

        $('#menu-btn').click(function() {
          $(".nav-primary").toggleClass("visible"); 
          $(".nav-secondary").toggleClass("visible"); 
          $(this).toggleClass("is-active"); 
        });

        $('.nav-search').click(function() {
          $('.search-field, .nav-close').toggleClass("show");
          $('.nav-search').toggleClass("hide");  
        });

        $('.nav-close').click(function() {
          $('.search-field, .nav-close').toggleClass("show");
          $('.nav-search').toggleClass("hide");  
        });

        //SLIDER

        $(".gallery").responsiveSlides({
            auto: false,
            pager: true,
            nav: true,
            prevText: "",
            nextText: "",  
        });
        
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
